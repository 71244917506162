import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { routes } from "@/config/routes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  ReleaseBreadcrumbs,
  useReleaseBreadcrumbsActions,
} from "../hooks/useReleaseBreadcrumbsActions";
import { ReleaseSequenceStepper } from "./ReleaseSequenceStepper";

export const OrderSideBySideBreadcrumbs = ({
  hasReleaseTextKey,
  noReleaseTextKey,
}: {
  hasReleaseTextKey: string;
  noReleaseTextKey: string;
}) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { actions } = useReleaseBreadcrumbsActions();

  const breadcrumbs = useMemo(() => {
    return [
      {
        text: intl.$t({ id: "ORDERS" }),
        link: routes.deliveries,
      },
      {
        text: release
          ? intl.$t({ id: hasReleaseTextKey })
          : intl.$t({ id: noReleaseTextKey }),
      },
    ] as BreadcrumbItem[];
  }, [hasReleaseTextKey, intl, noReleaseTextKey, release]);

  const breadcrumbsActions = useMemo(() => {
    return actions.flatMap((action) => {
      if (action === ReleaseBreadcrumbs.SequenceStepper) {
        return <ReleaseSequenceStepper />;
      }
      return [];
    });
  }, [actions]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      actions={breadcrumbsActions}
      appendItems={
        release
          ? [
              <ReleaseStatusChip
                key="status"
                status={release.status}
                type="breadcrumb"
                releaseType={release.type}
              />,
            ]
          : []
      }
    />
  );
};
