import { useMemo } from "react";
import { useReleaseSequence } from "../providers/ReleaseSequenceProvider";

export enum ReleaseBreadcrumbs {
  SequenceStepper,
}

export const useReleaseBreadcrumbsActions = () => {
  const { sequenceActive } = useReleaseSequence();

  const actions = useMemo(() => {
    if (sequenceActive) {
      return [ReleaseBreadcrumbs.SequenceStepper];
    }
    return [];
  }, [sequenceActive]);

  return {
    actions,
  };
};
