import {
  FooterCell,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterPropsWithTaxes,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useReleaseItemsZoneProvider } from "../../../providers/ReleaseItemsZonesProvider";

const SalesTaxContainer = tw.div`flex flex-col text-center`;

export const ReleaseExtPriceFooter: FC<FooterPropsWithTaxes> = ({
  Wrapper,
  includeAdditionalCharges,
}) => {
  const {
    extPriceSubtotal,
    extPriceSalesTax,
    extPriceSalesVariance,
    extPriceAdditionalCharges,
    noPrices,
  } = useReleaseItemsZoneProvider();

  return (
    <FooterCol>
      <Wrapper className="flex-col">
        <FooterHeader>
          <FormattedMessage id="CLIENT_ORDERED" tagName={FooterHeaderText} />
        </FooterHeader>
        <FooterCell>
          <NotNullableRenderer value={!noPrices}>
            <Price price={extPriceSubtotal} />
          </NotNullableRenderer>
        </FooterCell>
        <If isTrue={includeAdditionalCharges}>
          <FooterCell>
            <NotNullableRenderer value={!noPrices}>
              <Price price={extPriceAdditionalCharges} />
            </NotNullableRenderer>
          </FooterCell>
        </If>
        <FooterCell>
          <NotNullableRenderer value={!noPrices}>
            <SalesTaxContainer>
              <Price price={extPriceSalesTax} />
              <If isTrue={Number(extPriceSalesVariance)}>
                <Price
                  price={extPriceSalesVariance}
                  className="text-2xs text-gray-600"
                />
              </If>
            </SalesTaxContainer>
          </NotNullableRenderer>
        </FooterCell>
        <FooterDashedLine />
        <FooterTotal>
          <NotNullableRenderer value={!noPrices}>
            <Price
              price={extPriceSubtotal
                .plus(extPriceAdditionalCharges)
                .plus(extPriceSalesTax)
                .plus(extPriceSalesVariance)}
            />
          </NotNullableRenderer>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};
