import { BuyoutAdditionalChargesAndTaxes } from "@/common/components/buyout-additional-chatrges-and-taxes/BuyoutAdditionalChargesAndTaxes";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import {
  BuyoutStatus,
  DistributorBuyoutFieldsFragment,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { BuyoutList } from "../../BuyoutList";
import { BuyoutHeader } from "../../header/BuyoutHeader";
import { DistributorBuyoutItemsProvider } from "../../providers/DistributorBuyoutItemsProvider";
import { DistributorBuyoutInlineNotesAndTerms } from "../distributor-buyout-notes-and-terms/DistributorBuyoutInlineNotesAndTerms";

type Props = {
  buyout: DistributorBuyoutFieldsFragment;
};

export const BuyoutQuoted: FC<Props> = ({ buyout }) => {
  const total = useMemo(
    () =>
      new Decimal(buyout.amount)
        .plus(
          buyout.additionalCharges.reduce(
            (acc, charge) => acc.plus(charge.amount || 0),
            new Decimal(0),
          ),
        )
        .toString(),
    [buyout.amount, buyout.additionalCharges],
  );

  return (
    <DistributorBuyoutItemsProvider items={buyout.items}>
      <BuyoutHeader buyout={buyout} />
      <BuyoutList buyout={buyout} />
      <QuoteDocumentPanel quoteDocument={buyout.quoteDocument} readonly />
      <DistributorBuyoutInlineNotesAndTerms buyout={buyout} />
      <BuyoutAdditionalChargesAndTaxes
        buyout={buyout}
        total={total}
        readonlySalesTax
        includePaymentTerms
        includeAdditionalChargesAllowance
        readonlyAdditionalChargesAllowance
        readonly={!checkBuyoutStatus(buyout, [BuyoutStatus.Requested])}
      />
    </DistributorBuyoutItemsProvider>
  );
};
