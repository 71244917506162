import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
import { InvoiceUpdateReleaseNonItemizedView } from "../invoice-update-release/InvoiceUpdateReleaseNonItemizedView";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";

export const InvoiceCreateReleaseNonItemizedView = () => {
  const { setValue, ...rest } =
    useFormContext<InvoiceCreateReleaseFormValues>();
  const { invoice } = useInvoiceVerification();

  useEffect(() => {
    if (invoice && invoice.subtotal) {
      setValue("netAmount", Number(invoice.subtotal));
    }
  }, [setValue, invoice]);

  return <InvoiceUpdateReleaseNonItemizedView form={{ setValue, ...rest }} />;
};
