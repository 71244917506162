import {
  InstructionInput,
  TaxType,
  UpdateChargeInput,
} from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";

export type CreateOrderFromNoteFormValues = {
  businessLocationId: string;
  projectId: string;
  fulfillmentLocationId: string;
  vendorId: string;
  poNumber: string;
  orderDate: Date | null | undefined;
  timeTBD: boolean;
  requiresInventoryReceipt: boolean;
  willCall: boolean;
  vendorStocking: boolean;
  netAmount: string;
  paymentTerm: number | undefined;
  additionalCharges: UpdateChargeInput[];
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  clearCustomTaxAmount: boolean;
  total: string;
  orderTypeId: string;
  predictedPoNumber: string;
  vendorContactIds: string[] | null;
  instructions: InstructionInput | undefined;
  taxCodeId: string | undefined;
  taxType: TaxType | undefined;
  taxVariance: string | undefined | null;
  watcherIds: string[];
};

type CreateOrderForm = UseFormReturn<
  CreateOrderFromNoteFormValues,
  unknown,
  CreateOrderFromNoteFormValues
>;

export const CreateOrderFromNoteForm: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const createOrderFromNoteForm: CreateOrderForm =
    useForm<CreateOrderFromNoteFormValues>({
      defaultValues: {
        businessLocationId: "",
        projectId: "",
        fulfillmentLocationId: "",
        vendorId: "",
        orderDate: null,
        poNumber: "",
        requiresInventoryReceipt: false,
        willCall: false,
        vendorStocking: false,
        customTaxAmount: undefined,
        taxRate: "",
        additionalCharges: [],
        netAmount: "0",
        total: "0",
        instructions: {
          text: "",
          assetUrls: [],
        },
        predictedPoNumber: "",
        vendorContactIds: null,
        watcherIds: [],
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  return <FormProvider {...createOrderFromNoteForm}>{children}</FormProvider>;
};
